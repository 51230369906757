html, body, #root {
  height: 100%;
  margin: 0;
}

.homepage {
  background-image: url('./PG.png');
  background-size: cover; /* Ensures the image covers the whole area */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Ensures the image doesn't repeat */
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  display: flex;
  justify-content: center;
  align-items: center;
}
