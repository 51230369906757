        @font-face {
            font-family: 'Circular';
            src: url('../fonts/circular.ttf') format('truetype');
        }
        @font-face {
            font-family: 'Bebas';
            src: url('../fonts/bebas.ttf') format('truetype');
        }
        body {
            margin: 0;
            font-family: Arial, sans-serif;
        }
        nav {
            background-color: #E0C549;
            display: flex;
            padding: 24px;
            justify-content: space-between;
            align-items: center;
            opacity: 0;
            transform: translateY(-20px);
            transition: opacity 1s ease, transform 1s ease;
        }
        .nav-left {
            font-family: 'Circular', sans-serif;
            font-style: normal;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.03em;
            text-transform: uppercase;
            color: #174874;
        }
        .dash-left {
            font-family: 'Circular', sans-serif;
            font-style: normal;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.03em;
            color: #174874;
        }
        .nav-center {
            font-family: 'Circular', sans-serif;
            font-style: normal;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.03em;
            text-transform: uppercase;
            color: #174874;
        }
        .nav-right {
            font-family: 'Bebas', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: right;
            letter-spacing: -0.03em;
            color: #923023;
            color: color(display-p3 0.529 0.216 0.161);
        }

        @media only screen and (max-width: 768px) {
         .nav-left, .dash-left, .nav-center, .nav-right {
        font-size: 12px;
            }
        }
        
        .dynamic-dash {
            max-width: 760px;
            margin: 48px auto;
            opacity: 0;
            transform: translateY(20px);
            transition: opacity 1s ease, transform 1s ease;
        }

        @media (max-width: 768px) {
            .dynamic-dash {
                padding-left: 24px;
                padding-right: 24px;
            }
        }

        .dash-content {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }
        .low-opacity {
            opacity: 0.35;
        }
        .progress-bar {
            width: 100%;
            height: 8px;
            background-color: rgba(224, 197, 73, 0.35);
        }
        .progress {
            width: 0;
            height: 100%;
            background-color: #E0C549;
            transition: width 1.5s ease;
        }
        .photo-display {
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            opacity: 0;
            transition: opacity 1s ease;
        }
        .main-image {
            width: 60%;
            max-height: 80vh;
            object-fit: contain;
            opacity: 0;
            transform: scale(0.9);
            transition: opacity 1s ease, transform 1s ease;
        }
        .overlay-image {
            position: absolute;
            max-width: min(320px, 30%);
            bottom: 10%;
            right: 15%;
            opacity: 0;
            transform: translateX(20px);
            transition: opacity 1s ease, transform 1s ease;
        }

        /* Media query for smaller screens */
        @media (max-width: 768px) {
            .photo-display {
                height: 50vh;  /* Reduce height on smaller screens */
            }

            .main-image {
                object-position: center;  /* Center the image */
            }

            .overlay-image {
                max-width: 40%;  /* Slightly larger on smaller screens */
                max-height: 60%;  /* Allow more height on smaller screens */
            }
        }

        /* Media query for very small screens */
        @media (max-width: 480px) {
            .photo-display {
                height: 40vh;  /* Further reduce height on very small screens */
            }

            .overlay-image {
                max-width: 50%;  /* Even larger on very small screens */
                max-height: 70%;  /* Allow even more height on very small screens */
            }
        }
        .batch-info {
            max-width: 760px;
            margin: 0px auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            opacity: 0;
            transform: translateY(20px);
            transition: opacity 1s ease, transform 1s ease;
        }

              @media (max-width: 768px) {
            .batch-info {
                padding-left: 24px;
                padding-right: 24px;
            }
        }

        .batch-text {
            font-family: 'Circular', sans-serif;
            font-style: normal;
            font-weight: 200;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: -0.03em;
            color: #923023;
            color: color(display-p3 0.529 0.216 0.161);
        }
         @keyframes wobble-vertical {
    16.65% {
        transform: translateY(8px);
    }
    33.3% {
        transform: translateY(-6px);
    }
    49.95% {
        transform: translateY(4px);
    }
    66.6% {
        transform: translateY(-2px);
    }
    83.25% {
        transform: translateY(1px);
    }
    100% {
        transform: translateY(0);
    }
}

.view-photos-btn {
    padding: 12px 16px;
    cursor: pointer;
    background: #E6C420;
    background: color(display-p3 0.878 0.773 0.286);
    font-family: 'Circular', sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    color: #572525;
    border: none;
    text-decoration: none;
    transition: all 0.3s ease;
    display: inline-block;
}

.view-photos-btn:hover {
    animation-name: wobble-vertical;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}       

        #batch1 { background-color: #254771; }
        #batch2 { background-color: #873729; }
        #batch3 { background-color: #1D5743; }
        #batch4 { background-color: #E0C549; }
        #batch5 { background-color: #254771; }
        #batch6 { background-color: #873729; }
        #batch7 { background-color: #1D5743; }
        #batch8 { background-color: #E0C549; }
        #batch9 { background-color: #254771; }
        #batch10 { background-color: #873729; }
        #batch11 { background-color: #1D5743; }
        #batch12 { background-color: #E0C549; }
        #batch13 { background-color: #254771; }
        #batch14 { background-color: #873729; }
        #batch15 { background-color: #1D5743; }
        #batch15 { background-color: #E0C549; }